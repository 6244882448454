import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import BorderAllOutlinedIcon from "@material-ui/icons/BorderAllOutlined";
import TextField from '@material-ui/core/TextField';

import Logo from "./Logo";
import OutlineTag from "./OutlineTag";

const MaterialToolbar = (props) => {
  // const theme = useTheme();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (route) => {
    setAnchorEl(null);
    navigateTo(route);
  };

  const useStyles = makeStyles((theme) => ({
    flex: {
      flex: 1,
    },
    whiteButton: {
      color: "#fff",
    },
    tallWhiteButton: {
      color: "#fff",
      marginRight: 0,
      paddingLeft: 23,
      paddingRight: 23,
      textTransform: "none",
      fontWeight: 400,
      opacity: 0.9,
      height: theme.mixins.toolbar.minHeight,
      borderRadius: 0,
      "&:hover": {
        backgroundColor: "#ffffff19",
      },
    },
    tallWhiteButtonSelected: {
      color: "#fff",
      marginRight: 0,
      paddingLeft: 23,
      paddingRight: 23,
      textTransform: "none",
      fontWeight: 400,
      opacity: 1,
      height: theme.mixins.toolbar.minHeight,
      borderRadius: 0,
      backgroundColor: "#ffffff19",
      "&:hover": {
        backgroundColor: "#ffffff19",
      },
    },
  }));

  const navigateTo = (route) => {
    history.push(route);
  };

  const classes = useStyles();

  const currentLocation = window.location.pathname;

  const navItems = [
    {
      name: "Dashboard",
      icon: <BorderAllOutlinedIcon />,
      link: "/status",
    },
  ];

  return (
    <AppBar position={"static"} color={"primary"} elevation={0}>
      <Toolbar>
        <React.Fragment>
          <Hidden mdUp>
            <IconButton
              edge="start"
              className={classes.whiteButton}
              aria-label="menu"
              onClick={props.openSideDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <Tooltip title="Welcome">
              <span>
                <Logo click={() => navigateTo("/status")} />
              </span>
            </Tooltip>
            <OutlineTag text="beta / personal utility" color="#d82c20" />
            {navItems.map((item) => (
              <Button
                key={item.link}
                className={
                  currentLocation.startsWith(item.link)
                    ? classes.tallWhiteButtonSelected
                    : classes.tallWhiteButton
                }
                variant="text"
                startIcon={item.icon}
                onClick={() => navigateTo(item.link)}
              >
                {item.name}
              </Button>
            ))}
          </Hidden>
          <div className={classes.flex} />
          <OutlineTag text="Currently using API v1.1 which is out of date" color="#d82c20" />
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={props.globalSearchValue}
            onChange={(event) => { props.onUpdateGlobalSearch(event.target.value); }}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position='end'>
            //       <SearchIcon/>
            //     </InputAdornment>
            //   ),
            // }}
          />
          <IconButton
            className={classes.whiteButton}
            disableRipple
            onClick={() => navigateTo("/settings")}
          >
            <SettingsOutlinedIcon />
          </IconButton>
          <IconButton
            className={classes.whiteButton}
            onClick={handleClick}
            disableRipple
          >
            <AccountCircleOutlinedIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleClose("/logout")}>Log Out</MenuItem>
          </Menu>
        </React.Fragment>
      </Toolbar>
    </AppBar>
  );
};

MaterialToolbar.propTypes = {
  openSideDrawer: PropTypes.func,
  onUpdateGlobalSearch: PropTypes.func,
  globalSearchValue: PropTypes.string,
};

export default MaterialToolbar;
